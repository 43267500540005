
import './App.css';
import React, {StrictMode, Suspense } from "react"
import { routes } from './utils/routes';
import { Outlet, RouterProvider, createHashRouter } from "react-router-dom/dist"

function App() {

  return (
    <StrictMode>
      <Suspense fallback={< ></>}>
        <RouterProvider router={createHashRouter(routes)} />
        <Outlet />
      </Suspense>
    </StrictMode>
  );
}

export default App;
